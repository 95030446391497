import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { PasswordFormData } from '../components/Compounds/Modals/ResetPassword';
import {
  InventoryAsset,
  SupportTicketsItem,
  GroupedTickets,
  TicketsBarItem,
  PasswordValidation,
  WidgetCustomization
} from '../types';
import { getAccountName } from '../store/Accounts';
import { GridPreferences, PrefKey, UserPreferencesContent } from '../store/User';

export const createOrderFromIdDate = (id: string, date: Date) => ({
  id: id,
  name: 'name',
  order_number: '11',
  status: 'Pending Install',
  start_date: date.toISOString().slice(0, 10),
  bandwidth: '',
  contractEndDate: '',
  supplier: { id: '2', name: '2' },
  address: 'Address',
  nRC: 10,
  mRC: 20,
  term: '3 Months',
  orderType: '',
  product: { id: '3', name: '3' }
});

export const createInventoryFromId = (id: string, opts: Partial<Omit<InventoryAsset, 'Id'>> = {}): InventoryAsset => ({
  id: id,
  sfId: '',
  address: 'Address',
  addressInfo: {
    address: '',
    locationName: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    latitude: 20,
    longitude: 30,
    number: '1'
  },
  bandwidth: '1',
  billingFrequency: '',
  cageRackID: '',
  circuitIDs: '',
  circuitType: '',
  completionDate: '',
  serviceActivationDate: '',
  contractEndDate: '',
  iPDetails: '',
  kwRack: 1.0,
  lecCircuitID: '',
  locationContact: { name: '', email: '', phone: '' },
  mRC: 2.0,
  nRC: 3.0,
  orderType: '',
  phoneNumbers: '',
  product: { id: '', name: '' },
  rackType: '',
  status: '',
  supplier: { id: '', name: '' },
  term: '4 Months',
  locationALecCircuitId: '',
  locationALecDemarc: '',
  locationALocalLec: '',
  locationAText: '',
  locationId: '',
  locationZLecCircuitId: '',
  locationZLecDemarc: '',
  locationZLocalLec: '',
  locationZText: '',
  connectivityA: '',
  connectivityB: '',
  equipmentManufacturer: '',
  equipmentType: '',
  equipmentDetails: '',
  accessSpeed: '',
  portSpeed: '',
  disconnectedReason: '',
  accessType: '',
  firstInvoiceReviewedByDate: '',
  disconnectedDate: '',
  lecDemarc: '',
  accessProvider: '',
  providerAccountNumber: '',
  supplierOrderNumber: '',
  ...opts
});

export const monthsOptions = [{ value: '1', text: 'This Month' }].concat(
  [2, 4, 6, 9, 12].map((n) => {
    return { value: String(n), text: `${n} Months` };
  })
);

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function getFirstAndLastDayOfMonth(shortMonth: string): [firstDay: Date, lastDay: Date] {
  const monthNames: { [key: string]: number } = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11
  };
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const monthIndex = monthNames[shortMonth];
  const year = currentMonth >= monthIndex ? currentYear : currentYear - 1;
  const firstDay = new Date(year, monthIndex, 1);
  const lastDay = new Date(year, monthIndex + 1, 0);
  return [firstDay, lastDay];
}

export function filteredTicketsByStatusMonth(tickets: SupportTicketsItem[], months: number): SupportTicketsItem[] {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const startDate = new Date(currentYear, currentMonth - months, 1);
  const endDate = new Date(currentYear, currentMonth, 0);
  return tickets.filter((ticket) => {
    const ticketDate = new Date(ticket.dateTimeOpened);
    return (
      !['Escalated', 'Closed (Resolved/ Unconfirmed)', 'Merged'].includes(ticket.status) &&
      ticketDate >= startDate &&
      ticketDate <= endDate
    );
  });
}

export function filteredClosedTicketsByMonth(tickets: SupportTicketsItem[], months: number): SupportTicketsItem[] {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const startDate = new Date(currentYear, currentMonth - months, 1);
  const endDate = new Date(currentYear, currentMonth, 0);
  return tickets.filter((ticket) => {
    const ticketDate = ticket.dateTimeClosed ? new Date(ticket.dateTimeClosed) : null;
    return (
      ['Closed (Resolved/ Unconfirmed)', 'Merged'].includes(ticket.status) &&
      ticketDate &&
      ticketDate >= startDate &&
      ticketDate <= endDate
    );
  });
}

function dateByStatus(item: SupportTicketsItem): string {
  if (['Escalated', 'Closed (Resolved/ Unconfirmed)', 'Merged'].includes(item.status)) {
    return item.dateTimeClosed || '';
  } else {
    return item.dateTimeOpened;
  }
}

export function groupByMonthNumber(items: SupportTicketsItem[]): GroupedTickets[] {
  const groups: { [key: string]: number } = {};
  for (const item of items) {
    const date = new Date(dateByStatus(item));
    const month = `${date.getFullYear()}-${date.getMonth() + 1}`;
    if (groups[month] === undefined) {
      groups[month] = 1;
    } else {
      groups[month]++;
    }
  }
  const sortedGroups = Object.entries(groups)
    .map(([month, count]) => ({ month, count }))
    .sort((a, b) => {
      const [aYear, aMonth] = a.month.split('-').map(Number);
      const [bYear, bMonth] = b.month.split('-').map(Number);
      return aYear !== bYear ? aYear - bYear : aMonth - bMonth;
    });
  return sortedGroups;
}

function currentMonthByMonths(months: number): Date {
  const currentMonth = new Date();
  currentMonth.setMonth(currentMonth.getMonth() - (months - 1));
  return currentMonth;
}

function monthLabel(currentMonth: Date): string {
  return `${currentMonth.getFullYear()}-${currentMonth.getMonth() + 1}`;
}

function shortMonthName(currentMonth: Date): string {
  return currentMonth.toLocaleString('default', { month: 'short' });
}

function generateChartData(
  months: number,
  currentMonth: Date,
  groupedOpenTickets: GroupedTickets[],
  groupedClosedTickets: GroupedTickets[]
): TicketsBarItem[] {
  const chartData: TicketsBarItem[] = [];
  for (let i = 0; i < months; i++) {
    const monthName = shortMonthName(currentMonth);
    const matchingOpenGroup = groupedOpenTickets.find((group) => {
      return group.month === monthLabel(currentMonth);
    });
    const matchingClosedGroup = groupedClosedTickets.find((group) => {
      return group.month === monthLabel(currentMonth);
    });
    chartData.push({
      name: monthName,
      'Closed Tickets': matchingClosedGroup?.count || 0,
      'Open Tickets': matchingOpenGroup?.count || 0
    });
    currentMonth.setMonth(currentMonth.getMonth() + 1);
  }
  return chartData;
}

export const getDate = (dateString: string) => {
  const date = new Date(dateString);
  if (!dateString) return date;
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export function ticketsChartData(tickets: SupportTicketsItem[], months: number): TicketsBarItem[] {
  const groupedOpenTickets = groupByMonthNumber(filteredTicketsByStatusMonth(tickets, months));
  const groupedClosedTickets = groupByMonthNumber(filteredClosedTicketsByMonth(tickets, months));
  const currentMonth = currentMonthByMonths(months);
  return generateChartData(months, currentMonth, groupedOpenTickets, groupedClosedTickets);
}

export function parseQuarterToDates(quarterString: string): [Date, Date] {
  const year = Number(20 + quarterString.slice(0, 2));
  const quarter = Number(quarterString.slice(-1));
  if (quarter < 1 || quarter > 4) throw new Error('Quarter number must be between 1 and 4');

  const startDate = new Date(year, quarter * 3 - 3, 1);
  startDate.setUTCHours(0, 0, 0, 0);
  const endDate = new Date(year, quarter * 3, 0);
  endDate.setUTCHours(23, 59, 59, 999);

  return [startDate, endDate];
}

export function filteredByQuarterInventoryAssets(
  filteredInventoryAssets: InventoryAsset[],
  chartQuarter: string
): InventoryAsset[] {
  return filteredInventoryAssets
    .filter((inventoryAsset) => {
      const contractEndDate = getDate(inventoryAsset.contractEndDate);
      if (!chartQuarter) return true;

      const [startDate, endDate] = parseQuarterToDates(chartQuarter);
      return startDate <= contractEndDate && contractEndDate < endDate;
    })
    .sort((a, b) => getDate(a.contractEndDate).getTime() - getDate(b.contractEndDate).getTime());
}

export function calculateFutureDate(quarters: number, today: Date): Date {
  const daysInQuarter = 91;
  const futureDate = new Date(today.getTime() + quarters * daysInQuarter * 24 * 60 * 60 * 1000);
  return futureDate;
}

export const daysDifference = (today: Date, date: Date) => {
  return Math.abs(Math.floor((today.getTime() - date.getTime()) / (1000 * 3600 * 24)));
};

const dataGroupedByQuarters = (filteredInventoryAssets: InventoryAsset[]) => {
  return filteredInventoryAssets.reduce(
    (acc, inventoryAsset) => {
      const contractEndDate = getDate(inventoryAsset.contractEndDate);
      const quarter = Math.floor((contractEndDate.getMonth() + 3) / 3);
      const year = contractEndDate.getFullYear() % 2000;
      const key = `${year} ${quarter}`;
      if (acc[key]) {
        acc[key] += 1;
      } else {
        acc[key] = 1;
      }
      return acc;
    },
    {} as { [key: string]: number }
  );
};

const simplifiedKey = (key: string) => {
  const [year, quarter] = key.split(' ');
  return `${year} Q${quarter}`;
};

export const lineDataWithSimplifiedKey = (filteredInventoryAssets: InventoryAsset[]) => {
  const dataGroupedByQuarter = dataGroupedByQuarters(filteredInventoryAssets);

  const lineData = Object.keys(dataGroupedByQuarter).map((key) => {
    return { name: key, Expirations: dataGroupedByQuarter[key] };
  });

  const lineDataSortedByNames = lineData.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return lineDataSortedByNames.map((lineData) => {
    return { ...lineData, name: simplifiedKey(lineData.name) };
  });
};

export const filterInventoryAssets = (inventoryAssets: InventoryAsset[], selectedServiceTypes: string[]) => {
  return inventoryAssets.filter(
    (inventoryAsset) =>
      !!inventoryAsset.addressInfo.latitude &&
      !!inventoryAsset.addressInfo.longitude &&
      !!inventoryAsset.address &&
      selectedServiceTypes.includes(inventoryAsset.product?.family || '') &&
      FILTER_SERVICE_CATEGORIES.includes(inventoryAsset.product?.family || '')
  );
};

export const groupInventoryAssetsByAddress = (filteredAssets: InventoryAsset[]) => {
  return filteredAssets.reduce(
    (acc, asset) => {
      const address = asset.address;
      if (!address) return acc;

      const existingGroup = acc.find((group) => group[0] === address);
      if (existingGroup) {
        existingGroup[1].push(asset);
      } else {
        acc.push([address, [asset]]);
      }
      return acc;
    },
    [] as [string, InventoryAsset[]][]
  );
};

export const filterEmptyGroups = (groupedAssets: [string, InventoryAsset[]][]) => {
  return groupedAssets.filter((group) => group[1].length > 0);
};

export const generateMapData = (
  inventoryAssets: InventoryAsset[],
  selectedServiceTypes: string[]
): [string, InventoryAsset[]][] => {
  const filteredAssets = filterInventoryAssets(inventoryAssets, selectedServiceTypes);
  const groupedAssets = groupInventoryAssetsByAddress(filteredAssets);
  return filterEmptyGroups(groupedAssets);
};

export const generateDoughnutData = (inventoryAssets: InventoryAsset[]) => {
  const active = inventoryAssets.filter((asset) => {
    return asset.status === 'Active';
  }).length;
  const pendingInstall = inventoryAssets.filter((asset) => asset.status === 'Pending Install').length;
  const cancelled = inventoryAssets.filter((asset) => asset.status === 'Cancelled').length;
  const pendingDisconnect = inventoryAssets.filter((asset) => asset.status === 'Pending Disconnect').length;
  const disconnected = inventoryAssets.filter((asset) => asset.status === 'Disconnected').length;
  const installed = inventoryAssets.filter((asset) => asset.status === 'Installed').length;
  const total = active + pendingInstall + cancelled + pendingDisconnect + disconnected + installed;
  return [
    { name: 'Active', value: active, fill: '#2E01A4', percent: Math.round((active / total) * 100) },
    {
      name: 'Pending Install',
      value: pendingInstall,
      fill: '#D3D3D3',
      percent: Math.round((pendingInstall / total) * 100)
    },
    { name: 'Cancelled', value: cancelled, fill: '#E70600', percent: Math.round((cancelled / total) * 100) },
    {
      name: 'Pending Disconnect',
      value: pendingDisconnect,
      fill: '#454545',
      percent: Math.round((pendingDisconnect / total) * 100)
    },
    { name: 'Disconnected', value: disconnected, fill: '#454545', percent: Math.round((disconnected / total) * 100) },
    { name: 'Installed', value: installed, fill: '#85B9C1', percent: Math.round((installed / total) * 100) }
  ];
};

export const generateSuppliersDoughnutData = (inventoryAssets: InventoryAsset[]) => {
  const supplierAssets = inventoryAssets.filter((a) => !!a.supplier?.name);
  const supplierMap = supplierAssets.reduce((acc: Record<string, number>, asset) => {
    const supplierName = asset.supplier.name as string;
    acc[supplierName] = (acc[supplierName] || 0) + 1;
    return acc;
  }, {});
  const supplierEntries = Object.entries(supplierMap) as [string, number][];
  const totalCount = supplierAssets.length;
  supplierEntries.sort((a, b) => b[1] - a[1]);
  const topSuppliers = [];
  let otherCount = 0;
  type ColorMap = { [key: string]: string };
  const colors: ColorMap = {
    '0': '#D3D3D3',
    '1': '#2E01A4',
    '2': '#E70600',
    '3': '#454545'
  };

  for (let i = 0; i < supplierEntries.length; i++) {
    const [supplierName, count] = supplierEntries[i];
    const percent = Math.round((count / totalCount) * 100);
    if (i < Math.min(4, supplierEntries.length)) {
      topSuppliers.push({
        name: supplierName,
        fill: colors[i.toString()],
        percent
      });
    } else {
      otherCount += count;
    }
  }

  if (otherCount > 0) {
    topSuppliers.push({ name: 'Other', fill: '#85B9C1', percent: Math.round((otherCount / totalCount) * 100) });
  }

  return topSuppliers;
};

export const topSuppliers = (inventoryAssets: InventoryAsset[]) => {
  const supplierAssets = inventoryAssets.filter((a) => !!a.supplier?.name);
  const supplierMap = supplierAssets.reduce((acc: Record<string, number>, asset) => {
    const supplierName = asset.supplier.name as string;
    acc[supplierName] = (acc[supplierName] || 0) + 1;
    return acc;
  }, {});
  const supplierEntries = Object.entries(supplierMap) as [string, number][];
  supplierEntries.sort((a, b) => b[1] - a[1]);
  const totalCount = supplierAssets.length;
  const topSuppliers = [];
  for (let i = 0; i < supplierEntries.length; i++) {
    const [supplierName, count] = supplierEntries[i];
    const percent = Math.round((count / totalCount) * 100);
    topSuppliers.push({
      name: supplierName,
      percent
    });
  }
  return topSuppliers;
};

export const statusFormatter = (value: string) => {
  switch (value) {
    case 'Commissioning':
    case 'Completed':
      return 'Active';
    case 'Installed':
      return 'Installed';
    case 'Booked':
    case 'Pending Install':
      return 'Pending Install';
    case 'Cancelled':
    case 'Lost':
      return 'Cancelled';
    case 'Disconnected':
      return 'Disconnected';
    default:
      return value || 'In Progress';
  }
};

export const termFormatter = (value: string | number | null) => {
  switch (value) {
    case null:
      return '';
    case 0:
      return '0';
    case 1:
    case '1':
      return 'Month-to-Month';
    default:
      return `${value} Months`;
  }
};

export const usaCurrency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export function formatDate(d: string, isTime = false) {
  if (!d) return '';

  const result = d.split('-');

  if (isTime) {
    const date = d.split('/');
    const details = date[2].split(' ');
    const day = date[1].padStart(2, '0');
    const month = date[0].padStart(2, '0');
    const time = details[1].split(':');
    const hh = time[0].padStart(2, '0');

    return `${month}/${day}/${details[0]} ${hh}:${time[1]} ${details[2]}`;
  }

  return `${result[1]}/${result[2]}/${result[0]}`;
}

export function formatInfo(obj: string) {
  return <span style={{ whiteSpace: 'pre-wrap' }}>{obj}</span>;
}

export const getNestedValue = (obj: object, keyStr: string) => {
  const nestedVal = keyStr.split('.').reduce((o: object, i: string) => o?.[i as keyof typeof o], obj);
  return typeof nestedVal === 'string' ? nestedVal : '';
};

export const truncateStr = (val: string, maxLength = 20) =>
  val.length > maxLength ? `${val.substring(0, maxLength)}...` : val;

export const COLOCATION_SERVICE_CATEGORY = 'Colocation';
export const ROUTE1_COLOCATION_SERVICE_CATEGORY = 'Data Center and Cloud';
export const NETWORK_SERVICE_CATEGORY = 'Network';
export const ROUTE1_NETWORK_SERVICE_CATEGORY = 'Networking';
export const COMMUNICATIONS = 'Communications';
export const SOFTWARE_DEFINED_NETWORK = 'Software Defined Networking';
export const FILTER_SERVICE_CATEGORIES = [
  COLOCATION_SERVICE_CATEGORY,
  ROUTE1_COLOCATION_SERVICE_CATEGORY,
  NETWORK_SERVICE_CATEGORY,
  ROUTE1_NETWORK_SERVICE_CATEGORY,
  COMMUNICATIONS,
  SOFTWARE_DEFINED_NETWORK
];

export const objectNotEmpty = (obj: object) => !!Object.keys(obj).length;

export const getTicketClosedDate = (ticket: SupportTicketsItem): string => {
  const { dateTimeClosed } = ticket;

  if (!dateTimeClosed) return '';

  return formatSupportTicketDate(dateTimeClosed);
};

export const formatSupportTicketDate = (rawDate: string): string => {
  return new Date(rawDate)
    .toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    })
    .replace(',', '');
};

export const generateSearchUrl = (obj: object): string => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(obj)) {
    let paramValue = value;
    if (typeof value === 'object') {
      paramValue = JSON.stringify(value);
    }
    searchParams.append(key, paramValue);
  }

  return searchParams.toString();
};

export const HIDE_NAV_PAGES = ['offline', 'login', 'reset', 'welcome'];

export const DEFAULT_PASSWORD_VALIDATION: PasswordValidation = {
  length: { text: 'Between 8 and 72 characters', valid: false },
  upper: { text: '1 uppercase letter', valid: false },
  lower: { text: '1 lowercase letter', valid: false },
  digit: { text: '1 number', valid: false },
  special: { text: '1 special character [#?!@$%^&*-]', valid: false }
};

export const validatePassword = (formData: PasswordFormData) => {
  const result: { errorMsg: string; strengthUpdate: string; validationUpdate: PasswordValidation } = {
    errorMsg: '',
    strengthUpdate: '',
    validationUpdate: structuredClone(DEFAULT_PASSWORD_VALIDATION)
  };

  const length = formData.password.length;
  const password = formData.password;
  const confirm = formData.password_confirmation;
  const specialCharRegex = /[#?!@$%^&*-]/g;
  const numberRegex = /[0-9]/g;
  const lowerRegex = /[a-z]/;
  const upperRegex = /[A-Z]/;

  //Length
  if (length && length >= 8 && length <= 72) result.validationUpdate.length.valid = true;

  //UpperCase
  if (password && upperRegex.test(password)) result.validationUpdate.upper.valid = true;

  //LowerCase
  if (password && lowerRegex.test(password)) result.validationUpdate.lower.valid = true;

  //Special Char
  if (password && specialCharRegex.test(password)) result.validationUpdate.special.valid = true;

  //Numbers
  if (password && numberRegex.test(password)) result.validationUpdate.digit.valid = true;

  //Strength & Match: all cases above must pass to trigger this block.
  if (length && Object.values(result.validationUpdate).every((item) => item.valid)) {
    const specialLength = (password?.match(specialCharRegex) || []).length;
    const digitLength = (password?.match(numberRegex) || []).length;

    if (length === 8) result.strengthUpdate = 'weak';
    if (length > 10) result.strengthUpdate = 'fair';
    if (length > 12 && specialLength > 1 && digitLength > 1) result.strengthUpdate = 'strong';
    if (password && confirm && password !== confirm) result.errorMsg = 'Passwords do not match';
  }

  return result;
};

export const getSFHost = () => {
  return process.env.REACT_APP_SALESFORCE_INSTANCE_URL;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getObjValidity = (data: any, requiredKeys: string[]) => {
  const missingProperties = Object.keys(data)
    .filter((key) => requiredKeys.includes(key) && !data[key])
    .map((key) => key);

  return { errors: missingProperties, valid: missingProperties.length === 0 };
};

export const capitalizeText = (text: string): string => text[0].toUpperCase() + text.slice(1).toLowerCase();

export const sortWidgetCustomizations = (customizations: WidgetCustomization[]): WidgetCustomization[] => {
  return customizations.sort((widgetDefOne: WidgetCustomization, widgetDefTwo: WidgetCustomization) => {
    return widgetDefOne?.index - widgetDefTwo?.index;
  });
};

export const getValuesForColumn = (rows: GridValidRowModel[], field: string) => {
  return rows
    .reduce((acc, ele: GridValidRowModel) => {
      let val = field.includes('.') ? getNestedValue(ele, field) : ele[field];

      // Will need a better way to do this if there are more transforms in the future.
      if (field === 'account_id') {
        val = getAccountName(val);
      }

      if (val && !acc.includes(val)) acc.push(val);

      return acc;
    }, [])
    .sort();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrayMove = (arr: any[], from: number, to: number) => {
  const element = arr[from];
  arr.splice(from, 1);
  arr.splice(to, 0, element);
};

export const getTabListItemClassName = (index: number, selectedIndex?: number): string => {
  return `${
    index === selectedIndex ? 'border-transparent bg-grey-0 border-b-0' : 'text-gray-500 hover:text-gray-700'
  } whitespace-nowrap py-4 px-5 font-medium text-sm`;
};

export const getTabs = (obj: UserPreferencesContent, prefix: string, customOnly?: boolean): string[] => {
  const allTabs = Object.keys(obj)
    .filter((key) => key.startsWith(prefix))
    .map((key) => key.substring(prefix.length))
    .sort((a, b) => (a < b ? -1 : 1));

  const customTabs = Object.keys(obj)
    .filter((key) => key.startsWith(prefix) && ((obj?.[key as PrefKey] || {}) as GridPreferences).customView)
    .map((key) => key.substring(prefix.length))
    .sort((a, b) => (a < b ? -1 : 1));

  return customOnly ? customTabs : allTabs;
};
