import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Order } from '../../../types';
import { PageLayout } from '../../PageLayout';
import { PageCard } from '../../Compounds/CardWithTitle';
import { Text } from '../../Atoms/Text';
import { getOrderSubject, getOrdersLoadingSubject, useOrders } from '../../../store/Order';
import {
  GridPreferences,
  getDefaultPreferences,
  getPreferencesByKey,
  getUserPreferencesSubject
} from '../../../store/User';
import { DetailsDrawer } from '../../Compounds/DetailsDrawer';
import { getMetaData } from '../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { UpstackDataGrid } from '../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { DefaultOrderGridColumnDefs } from './OrderGridColumnDefs';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { getGridColumns } from '../../Compounds/UpstackDataGrid/helpers';
import { getRoute1Subject } from '../../../store/Route1';

const headerContentText = (rowCount: number, displayedRowCount: number | undefined) => {
  if (!rowCount) return '';

  const text = `${rowCount} Item${rowCount > 1 ? 's' : ''}`;
  if (rowCount == displayedRowCount || displayedRowCount === undefined) return text;

  return `${displayedRowCount} of ${text}`;
};

export const displayedRowCountContent = (rowCount: number, displayedRowCount: number | undefined) => {
  return (
    <Text
      dataTestId="order-count"
      className="font-medium grow ml-4"
      size="sm14"
      color="grey4">
      {headerContentText(rowCount, displayedRowCount)}
    </Text>
  );
};

export const OrdersPage = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const page = 'Orders';
  const { fetchOrders } = useOrders();
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [orderColumns, setOrderColumns] = useState<GridSingleSelectColDef[]>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences().content['order_grid'] as GridPreferences
  );
  const [search] = useSearchParams();
  const detailId = search.get('detailId');

  useEffect(() => {
    const subscription = getOrderSubject().subscribe((orders) => {
      setOrders(orders);
      setOrderColumns(getGridColumns(orders, DefaultOrderGridColumnDefs, 'order_grid'));
    });
    const settingsSub = getUserPreferencesSubject().subscribe(() => {
      const gridPrefs = getPreferencesByKey('order_grid') as GridPreferences;
      if (gridPrefs) {
        setGridSettings(gridPrefs);
      }
    });
    const loadingSubscription = getOrdersLoadingSubject().subscribe((loading) => setOrdersLoading(loading));
    /**
     * @TODO REMOVE AFTER ROUTE1 migration
     */
    const route1Sub = getRoute1Subject().subscribe((enabled) => enabled && fetchOrders(true));

    fetchOrders();

    return () => {
      if (subscription) subscription.unsubscribe();
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (settingsSub) settingsSub.unsubscribe();
      if (route1Sub) route1Sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (orders.length) {
      const colsUpdate = getGridColumns(orders, DefaultOrderGridColumnDefs, 'order_grid');
      setOrderColumns(colsUpdate);
    }
  }, [orders.length]);

  return (
    <PageLayout pageTitle={page}>
      <PageCard
        childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
        title={page}>
        {orderColumns && !ordersLoading ? (
          <UpstackDataGrid
            dataCy="orders-data-grid"
            title="Orders"
            rows={orders}
            columns={orderColumns}
            loadingData={ordersLoading}
            pinnedColumns={{ left: ['details', 'account_id', 'sfId'] }}
            page="order_grid"
            gridSettings={gridSettings}
            showSearch={false}
          />
        ) : (
          <></>
        )}
      </PageCard>
      <div className="h-2"></div>
      <DetailsDrawer
        data={orders?.find((o: Order) => o?.id === detailId) || ({} as Order)}
        showDrawer={!!detailId}
        link={`/orders/${detailId}`}
        {...getMetaData('order')}
      />
    </PageLayout>
  );
};
