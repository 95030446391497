import { useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { Order } from '../types';
import { fetchOrders as apiFetchOrders } from '../Api/Orders';
import { filterOrdersData } from '../utils/ordersHelper';
import { statusFormatter, termFormatter } from '../utils/helpers';
import { addError } from './Error';
import { getSelectedAccountIdsForUrl } from './User';

export interface UseOrdersReturn {
  setOrdersData: (orders: Order[]) => void;
  fetchOrders: (forceRefresh?: boolean) => Promise<void>;
  clear: () => void;
  orders: Order[];
}

export const isOrder = (data: unknown): data is Order => {
  return typeof data === 'object' && data !== null && !!(data as Order)?.sfId;
};

const defaultOrderData: Order[] = [];

const subject = new BehaviorSubject<Order[]>(defaultOrderData);
const emitErrorState = (message?: string): void => addError('order', message);
const ordersLoadingSubject = new BehaviorSubject<boolean>(false);
export const getOrderSubject = (): BehaviorSubject<Order[]> => subject;
export const getOrders = (): Order[] => subject.value;
export const getOrdersLoadingSubject = (): BehaviorSubject<boolean> => ordersLoadingSubject;

export const useOrders = (): UseOrdersReturn => {
  const [orders, setOrders] = useState(defaultOrderData);

  const fetchOrders = async (forceRefresh = false): Promise<void> => {
    if (ordersLoadingSubject.getValue()) return;
    if (subject.getValue().length > 0 && !forceRefresh) return;
    ordersLoadingSubject.next(true);

    const { data, error } = await apiFetchOrders(getSelectedAccountIdsForUrl());
    if (data) {
      emitErrorState();
      const ordersData: Order[] = filterOrdersData(data).map((i) => {
        i.productDisplay = `${i.product?.family} - ${i.product?.name}`;
        i.term = termFormatter(i.term);
        i.status = statusFormatter(i.status);
        return i;
      });
      setOrdersData(ordersData);
      setOrders(ordersData);
    }

    if (error) {
      setOrdersData(defaultOrderData);
      emitErrorState(error.message);
    }

    ordersLoadingSubject.next(false);
  };

  const setOrdersData = (orders: Order[]) => subject.next(orders);
  const clear = () => subject.next(defaultOrderData);

  return { setOrdersData, clear, orders, fetchOrders };
};
