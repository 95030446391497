import { useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { InventoryAsset } from '../types';
import { statusFormatter, termFormatter } from '../utils/helpers';
import { addError } from './Error';
import { getSelectedAccountIdsForUrl } from './User';
import { fetchAggregateInventory, fetchAggregateInventoryById } from '../Api/Inventory/aggregateInventoryApi';
import { deleteOutsideInventory } from '../Api/OutsideInventory/outsideInventoryApi';
import { useSnackbar } from '../Context/SnackbarContext';

export interface UseInventoryReturn {
  fetchInventory: (forceRefresh?: boolean) => Promise<void>;
  fetchInventoryById: (inventoryId: string) => Promise<void>;
  setInventoryData: (inventory: InventoryAsset[]) => void;
  deleteInventoryById: (id: string) => Promise<void>;
  clear: () => void;
  inventory: InventoryAsset[];
}

export const isInventoryItem = (data: unknown): data is InventoryAsset => {
  return typeof data === 'object' && data !== null && !!(data as InventoryAsset)?.sfId;
};

const defaultInventoryData: InventoryAsset[] = [];

const subject = new BehaviorSubject<InventoryAsset[]>(defaultInventoryData);
const inventoryByIdSubject = new BehaviorSubject<InventoryAsset | undefined>(undefined);
const loadingSubject = new BehaviorSubject<boolean>(false);
const emitErrorState = (message?: string) => addError('inventory', message);

export const getInventorySubject = (): BehaviorSubject<InventoryAsset[]> => subject;
export const getInventoryByIdSubject = (): BehaviorSubject<InventoryAsset | undefined> => inventoryByIdSubject;
export const getInventoryLoadingSubject = (): BehaviorSubject<boolean> => loadingSubject;
export const getInventory = (): InventoryAsset[] => subject.value;
export const getInventoryById = (id: string): InventoryAsset | undefined =>
  subject.value.find((inv: InventoryAsset) => inv.id === id);
export const getAddress = (id: string) => {
  const inventory = subject.getValue();
  return inventory.find((i) => i.id === id)?.address;
};
export const useInventory = (): UseInventoryReturn => {
  const [inventory, setInventory] = useState(defaultInventoryData);
  const { setSnack } = useSnackbar();

  const fetchInventory = async (forceRefresh = false): Promise<void> => {
    if (loadingSubject.getValue()) return;
    if (subject.getValue().length > 0 && !forceRefresh) return;
    loadingSubject.next(true);

    const { data, error } = await fetchAggregateInventory(getSelectedAccountIdsForUrl());
    if (data) {
      emitErrorState();
      const inventoryAssets: InventoryAsset[] = (data as InventoryAsset[]).map((i) => {
        const isUpstackManaged = !i.outside_inventory_schema_id;
        i.productDisplay = `${i.product?.family} - ${i.product?.name}`;
        i.status = statusFormatter(i.status);
        i.term = isUpstackManaged ? termFormatter(i.term) : i.term;
        i['UPSTACK Managed'] = isUpstackManaged;
        return i;
      });
      setInventoryData(inventoryAssets);
      setInventory(inventoryAssets);
    }

    if (error) {
      setInventoryData(defaultInventoryData);
      emitErrorState(error.message);
    }

    loadingSubject.next(false);
  };

  const fetchInventoryById = async (inventoryId: string): Promise<void> => {
    loadingSubject.next(true);
    const invItem = subject.value.find((inv: InventoryAsset) => inv.id === inventoryId);

    if (invItem) {
      inventoryByIdSubject.next(invItem);
    } else {
      const { data, error } = await fetchAggregateInventoryById(inventoryId, getSelectedAccountIdsForUrl());
      if (data) inventoryByIdSubject.next((data as InventoryAsset[])[0]);
      if (error) emitErrorState(error.message);
    }

    loadingSubject.next(false);
  };

  const deleteInventoryById = async (id: string): Promise<void> => {
    const { error } = await deleteOutsideInventory(id);
    if (error) return setSnack({ message: 'Inventory not deleted', type: 'error', open: true });
    setSnack({ message: 'Inventory deleted successfully', type: 'success', open: true });
  };

  const setInventoryData = (inventory: InventoryAsset[]) => subject.next(inventory);
  const clear = () => subject.next(defaultInventoryData);

  return { setInventoryData, clear, inventory, fetchInventory, fetchInventoryById, deleteInventoryById };
};
