/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Order } from '../../../types';
import { getNestedValue } from '../../../utils/helpers';
import { DetailViewCard } from '../../Compounds/CardWithTitle';
import { DetailRow } from '../../Compounds/DetailsDrawer/DetailRow';
import { useEffect, useState } from 'react';
import { getOrdersLoadingSubject } from '../../../store/Order';
import { AttachmentTable } from '../../Compounds/AttachmentTable';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';
import { DefaultOrderGridColumnDefs } from './OrderGridColumnDefs';
import { DetailsComments } from '../../Compounds/DetailsComments/DetailsComments';
import { defaultFeatureFlags, FeatureFlags, getFeatureFlagSubject } from '../../../Api/useFeatureFlags';
import { shouldSeeComments } from '../../../store/User';

export interface OrderDetailsProps {
  data: Order;
}

export function OrderDetails({ data }: OrderDetailsProps) {
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);

  useEffect(() => {
    const ordersLoadingSub = getOrdersLoadingSubject().subscribe((loading: boolean) => {
      setOrdersLoading(loading);
    });
    const flags = getFeatureFlagSubject().subscribe((flags) => setFeatureFlags(flags));

    return () => {
      if (ordersLoadingSub) ordersLoadingSub.unsubscribe();
      if (flags) flags?.unsubscribe();
    };
  }, []);

  const fieldList: { colId: string; title: string }[] = DefaultOrderGridColumnDefs.map((item) => {
    return {
      colId: item?.field,
      title: item?.headerName || ''
    };
  }).filter((field) => !['id', 'detailCTA', 'sfId'].includes(field.colId));

  const getValue = (key: string) => {
    if (JSON.stringify(data) === '{}') return ''; // TODO: temporary fix
    if (key === 'product') return `${data?.product?.family} - ${data?.product?.name}`;
    if (key.includes('.')) return getNestedValue(data, key);

    const val = data[key as keyof Order] as string;
    return val || '';
  };

  return (
    <>
      <DetailViewCard
        collapsed={false}
        enableCollapse
        key="order"
        title="Details">
        <div className="overflow-auto h-[30vh]">
          <>
            {ordersLoading && (
              <div className="mt-32">
                <ComponentSpinner />
              </div>
            )}
            {!ordersLoading &&
              fieldList.map((field) => (
                <DetailRow
                  key={field.colId}
                  colId={field.colId}
                  title={field.title}
                  value={getValue(field.colId) || ''}
                />
              ))}
          </>
        </div>
      </DetailViewCard>
      {data.id && <AttachmentTable id={data.id} />}
      {featureFlags.comments && shouldSeeComments() && data.id && (
        <DetailsComments
          id={data.id}
          entityType="Inventory"
          entityAccountId={data.account_id}
        />
      )}
    </>
  );
}
