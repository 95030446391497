import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dialog, DialogContent, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ComponentName } from '../detailsDrawersHelpers';
import { PermissibleRender } from '../../PermissibleRender';
import { AttachmentQueue } from '../../AttachmentQueue';
import { AttachmentCTA } from '../../AttachmentCTA';
import { Permission } from '../../../../store/GeneralStore';
import { useAttachments } from '../../../../Api/Attachments/useAttachments';
import { Attachment, InventoryAsset, Order, PortalUserData, SupportTicketsItem, User } from '../../../../types';
import { getSFHost } from '../../../../utils/helpers';
import { isSupportTicket, useSupportTickets } from '../../../../store/SupportTicket';
import { isInventoryItem, useInventory } from '../../../../store/Inventory';
import { isOrder, useOrders } from '../../../../store/Order';
import { Icon } from '../../../Atoms/Icon';
import { getUserSubject } from '../../../../store/User';
import { SKIP_ACCOUNT_ROLES } from '../../../../store/Role';
import { AttachmentState, getSubject } from '../../../../store/Attachment';
import { deleteTeam, Team } from '../../../../store/Team';
import { CardButton } from '../../Buttons';
import { AccountData } from '../../../../store/Accounts';
import { ConfirmationModal } from '../../../Pages/AddInventory/ConfirmationModal';
import { useS3Attachments } from '../../../../store/S3Attachment';

export interface DrawerActionsProps {
  componentName?: ComponentName;
  data: InventoryAsset | SupportTicketsItem | Order | PortalUserData | Team | AccountData | null;
  setShow: (show: boolean) => void;
}

export const DrawerActions = ({ componentName, data, setShow }: DrawerActionsProps) => {
  const { uploadAttachments, cancelUpload, retryUpload, setParentId } = useAttachments();
  const { queuedS3Attachments, cancelS3Upload, updateS3Attachments, retryS3Upload, setS3EntityId, setS3EntityType } =
    useS3Attachments();
  const { fetchTickets } = useSupportTickets();
  const { fetchInventory, deleteInventoryById } = useInventory();
  const { fetchOrders } = useOrders();

  const [search] = useSearchParams();
  const navigate = useNavigate();
  const detailId = search.get('detailId');

  // TODO: abstract into role based wrapping component to conditionally show and hide based on roles/personas
  const [showLink, setShowLink] = useState<boolean>(false);
  const [userData, setUserData] = useState<User>();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [queuedAttachments, setQueuedAttachments] = useState<Attachment[]>([]);

  const shouldShowLink = (user: User) => {
    const userShouldSeeLink = user?.roles?.some(({ name }) => name && SKIP_ACCOUNT_ROLES.includes(name));

    if (!userShouldSeeLink) return false;

    return !(componentName === 'inventory' && data && !(data as InventoryAsset)['UPSTACK Managed']);
  };

  useEffect(() => {
    if (userData) setShowLink(shouldShowLink(userData));
  }, [userData, data?.id]);

  useEffect(() => {
    const userSub = getUserSubject().subscribe((user) => setUserData(user));
    const attachmentSubscription = getSubject().subscribe(({ queuedAttachments }: AttachmentState) => {
      setQueuedAttachments(queuedAttachments.filter((att) => att.parentId === detailId));
    });

    return () => {
      if (userSub) userSub.unsubscribe();
      if (attachmentSubscription) attachmentSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (data && data.id && componentName && ['support', 'inventory', 'order'].includes(componentName)) {
      if (!(data as InventoryAsset)['UPSTACK Managed']) {
        setS3EntityId(data.id);
        setS3EntityType('OutsideInventory');
      } else {
        setParentId(data.id);
      }
    }
  }, [data?.id]);

  const deleteOutsideInventory = (id: string | null) => {
    if (id) {
      deleteInventoryById(id);
      fetchInventory(true);
      navigate('/inventory');
    }
  };

  const defaultButtons = () => {
    const fetchActions = {
      support: {
        fetchFunc: fetchTickets,
        deleteFunc: null,
        segment: 'Case'
      },
      order: {
        fetchFunc: fetchOrders,
        deleteFunc: null,
        segment: 'Asset'
      },
      inventory: {
        fetchFunc: fetchInventory,
        deleteFunc: deleteOutsideInventory,
        segment: 'Asset'
      }
    };
    const urlSegment = fetchActions?.[componentName as keyof typeof fetchActions].segment;
    const linkId = isSupportTicket(data) || isInventoryItem(data) || isOrder(data) ? data.sfId : detailId;
    const url = `${getSFHost()}/lightning/r/${urlSegment}/${linkId}/view`;

    return (
      <>
        <Tooltip
          title="Refresh"
          placement="top">
          <IconButton
            className="self-center"
            data-testid="refreshButton"
            onClick={() => fetchActions?.[componentName as keyof typeof fetchActions].fetchFunc(true)}>
            <Icon type="refresh" />
          </IconButton>
        </Tooltip>
        {showLink && (
          <Tooltip
            title="Open in SF"
            placement="top">
            <IconButton
              className="self-center"
              data-testid="openInSalesforceButton"
              href={url}
              target="_blank">
              <Icon type="external" />
            </IconButton>
          </Tooltip>
        )}
        {(data as InventoryAsset)['UPSTACK Managed'] === false && (
          <PermissibleRender requiredPermissions={[Permission.DELETE_OUTSIDE_INVENTORY]}>
            <>
              <IconButton
                className="self-center"
                data-testid="deleteButton"
                onClick={() => setConfirmationModalOpen(true)}>
                <Icon
                  type="trash"
                  className="p-2"
                />
              </IconButton>
              <Dialog
                open={confirmationModalOpen}
                onClose={() => setConfirmationModalOpen(false)}>
                <IconButton
                  aria-label="close"
                  onClick={() => setConfirmationModalOpen(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 4,
                    color: (theme) => theme.palette.grey[500]
                  }}>
                  <CloseIcon />
                </IconButton>
                <DialogContent>
                  <ConfirmationModal
                    close={() => setConfirmationModalOpen(false)}
                    items={0}
                    ctaAction={() => fetchActions?.[componentName as keyof typeof fetchActions].deleteFunc?.(detailId)}
                    type="Delete"
                    wrapperClassName="max-w-[30vw] min-w-[20vw]"
                    modalClassName="pb-2 text-center"
                  />
                </DialogContent>
              </Dialog>
            </>
          </PermissibleRender>
        )}
      </>
    );
  };

  const getActions = () => {
    switch (componentName) {
      case 'inventory':
        return (
          <>
            <div className="flex items-center mb-2">
              <PermissibleRender requiredPermissions={[Permission.ADD_ATTACHMENTS_INVENTORY]}>
                <AttachmentCTA
                  addAttachments={(data as InventoryAsset)['UPSTACK Managed'] ? uploadAttachments : updateS3Attachments}
                />
              </PermissibleRender>
              {defaultButtons()}
            </div>
            <div className="flex">
              <PermissibleRender requiredPermissions={[Permission.ADD_ATTACHMENTS_INVENTORY]}>
                <AttachmentQueue
                  queuedAttachments={
                    (data as InventoryAsset)['UPSTACK Managed'] ? queuedAttachments : queuedS3Attachments
                  }
                  cancelUpload={(data as InventoryAsset)['UPSTACK Managed'] ? cancelUpload : cancelS3Upload}
                  retryUpload={(data as InventoryAsset)['UPSTACK Managed'] ? retryUpload : retryS3Upload}
                />
              </PermissibleRender>
            </div>
          </>
        );
      case 'order':
        return (
          <>
            <div className="flex items-center mb-2">
              <PermissibleRender requiredPermissions={[Permission.ADD_ATTACHMENTS_INVENTORY]}>
                <AttachmentCTA addAttachments={uploadAttachments} />
              </PermissibleRender>
              {defaultButtons()}
            </div>
            <div className="flex">
              <PermissibleRender requiredPermissions={[Permission.ADD_ATTACHMENTS_INVENTORY]}>
                <AttachmentQueue
                  queuedAttachments={queuedAttachments}
                  cancelUpload={cancelUpload}
                  retryUpload={retryUpload}
                />
              </PermissibleRender>
            </div>
          </>
        );
      case 'support':
        return (
          <>
            <div className="flex items-center mb-2">
              <PermissibleRender requiredPermissions={[Permission.ADD_TICKETS_ATTACHMENTS]}>
                <AttachmentCTA addAttachments={uploadAttachments} />
              </PermissibleRender>
              {defaultButtons()}
            </div>
            <div className="flex">
              <PermissibleRender requiredPermissions={[Permission.ADD_TICKETS_ATTACHMENTS]}>
                <AttachmentQueue
                  queuedAttachments={queuedAttachments}
                  cancelUpload={cancelUpload}
                  retryUpload={retryUpload}
                />
              </PermissibleRender>
            </div>
          </>
        );
      case 'team':
        return (
          <>
            <div className="flex items-center mb-2 ml-6">
              {!!data?.id && (
                <Tooltip
                  title="Delete"
                  placement="top">
                  <CardButton
                    data-testid="delete-team"
                    data-cy="drawer-delete-team-btn"
                    icon="trash"
                    text="Delete Team"
                    onClick={() => {
                      deleteTeam(data.id);
                      setShow(false);
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return getActions();
};
