import { HelmetProvider, Helmet } from 'react-helmet-async';
import Text from './Atoms/Text';

export interface PageLayoutProps {
  pageTitle?: string;
  children?: React.ReactNode;
  pageActions?: React.ReactElement | React.ReactElement[];
  enableActions?: boolean;
  showTitle?: boolean;
  className?: string;
  width?: string;
  actionLocation?: string;
  dataCy?: string;
}

export const PageLayout = ({
  pageTitle,
  children,
  pageActions,
  enableActions = true,
  className,
  width = 'w-11/12',
  actionLocation = 'top',
  showTitle = true,
  dataCy
}: PageLayoutProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`UPSTACK Portal ${pageTitle ? ` - ${pageTitle}` : ''}`}</title>
      </Helmet>
      <div
        data-cy={dataCy || ''}
        className={`h-screen ${width} page-layout m-auto basis-full ${!pageTitle ? 'mt-12' : ''} ${className || ''}`}>
        <div className={`flex w-full items-center ${pageTitle ? 'mt-8' : ''}`}>
          {showTitle && (
            <Text
              color="grey8"
              size="xl"
              weight="medium"
              dataCy="page-title">
              {pageTitle}
            </Text>
          )}
          {enableActions && actionLocation === 'top' && <div className="ml-auto">{pageActions}</div>}
        </div>
        {children}
        {enableActions && actionLocation === 'bottom' && <div className="mt-5">{pageActions}</div>}
      </div>
    </HelmetProvider>
  );
};
