import { BehaviorSubject } from 'rxjs';
import { v4 } from 'uuid';
import { InventoryAsset, OutsideInventorySchema, Product } from '../types';
import { FormStateStatus } from './BulkForm';
import {
  FileValidationResponse,
  createOutsideInventory,
  updateOutsideInventory,
  uploadXLFileValidation
} from '../Api/OutsideInventory/outsideInventoryApi';
import { getSupplierById } from './Supplier';
import { addError } from './Error';
import axios from 'axios';
import { ProgressHandler } from '../Api/Attachments/uploadAttachment';
import DOMPurify from 'dompurify';

const emitErrorState = (message?: string): void => addError('outside-inventory', message);

export interface OutsideInventoryFormProps {
  dirty: boolean;
  valid: boolean;
  id: string;
  formData: InventoryAsset;
}

export interface CreateOutsideInventoryState {
  id: string;
  status: FormStateStatus;
  error?: string;
  data?: Partial<InventoryAsset>;
  requestBody: InventoryAsset;
}

export const defaultOutsideInventoryForm = (): OutsideInventoryFormProps => {
  const id = v4();

  return {
    id,
    dirty: false,
    valid: false,
    formData: {
      address: '',
      bandwidth: '',
      billingFrequency: '',
      cageRackID: '',
      circuitIDs: '',
      circuitType: '',
      completionDate: '',
      serviceActivationDate: '',
      connectivityA: '',
      connectivityB: '',
      contractEndDate: '',
      disconnectedDate: '',
      equipmentManufacturer: '',
      equipmentType: '',
      firstInvoiceReviewedByDate: '',
      id: id,
      sfId: '',
      iPDetails: '',
      kwRack: 0,
      lecCircuitID: '',
      lecDemarc: '',
      accessProvider: '',
      locationContact: {
        name: '',
        phone: '',
        email: ''
      },
      locationALecCircuitId: '',
      locationALecDemarc: '',
      locationALocalLec: '',
      locationAText: '',
      locationId: '',
      locationZLecCircuitId: '',
      locationZLecDemarc: '',
      locationZLocalLec: '',
      locationZText: '',
      mRC: 0,
      nRC: 0,
      orderType: '',
      phoneNumbers: '',
      product: {
        id: '',
        name: '',
        family: '',
        productCode: ''
      },
      providerAccountNumber: '',
      supplierOrderNumber: '',
      rackType: '',
      status: 'Active',
      supplier: {
        id: '',
        name: ''
      },
      term: '',
      equipmentDetails: '',
      accessSpeed: '',
      portSpeed: '',
      disconnectedReason: '',
      accessType: '',
      addressInfo: {
        address: '',
        locationName: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        latitude: 0,
        longitude: 0,
        number: ''
      },
      outside_inventory_schema_id: '',
      account_id: '',
      product_id: '',
      supplier_id: null,
      showAdvanced: false
    }
  };
};

const outsideInventoryFormSubject = new BehaviorSubject<OutsideInventoryFormProps[]>([defaultOutsideInventoryForm()]);
const outsideInventoryCreateStateSubject = new BehaviorSubject<CreateOutsideInventoryState[]>([]);
const outsideInventoryFormValidSubject = new BehaviorSubject<boolean>(false);
const outsideInventoryValidationStateSubject = new BehaviorSubject<FileValidationResponse>(
  {} as FileValidationResponse
);

export const getOutsideInventoryValidationStateSubject = (): BehaviorSubject<FileValidationResponse> =>
  outsideInventoryValidationStateSubject;

export const getOutsideinventoryFormSubject = (): BehaviorSubject<OutsideInventoryFormProps[]> =>
  outsideInventoryFormSubject;

export const getOutsideInventoryCreateStateSubject = (): BehaviorSubject<CreateOutsideInventoryState[]> =>
  outsideInventoryCreateStateSubject;

export const getOutsideInventoryFormValidSubject = (): BehaviorSubject<boolean> => outsideInventoryFormValidSubject;

export const clearForm = () => {
  outsideInventoryFormSubject.next([defaultOutsideInventoryForm()]);
  checkFormsValid();
};

export const clearFormCreateSubject = () => outsideInventoryCreateStateSubject.next([]);

export const addForm = () => {
  const currentValue = [...outsideInventoryFormSubject.getValue()];
  const newForm = defaultOutsideInventoryForm();
  outsideInventoryFormSubject.next([...currentValue, newForm]);
  checkFormsValid();
};

export const removeForm = (id: string) => {
  const update = [...outsideInventoryFormSubject.getValue()];
  const index = update.findIndex((item) => item?.id === id);
  if (index > -1) update.splice(index, 1);
  outsideInventoryFormSubject.next(update);
  checkFormsValid();
};

export const updateForm = (id: string, data: Partial<InventoryAsset>, dirty = true, valid = false) => {
  const update = [...outsideInventoryFormSubject.getValue()];
  const index = update.findIndex((form) => form.id === id);
  if (index > -1) {
    const updatedFormData = { ...update[index].formData, ...(data as InventoryAsset) };
    if (
      updatedFormData?.supplier_id &&
      (!updatedFormData?.supplier.id || updatedFormData?.supplier.id !== updatedFormData.supplier_id)
    ) {
      const matchedSupplier = getSupplierById(updatedFormData.supplier_id);
      updatedFormData.supplier = {
        id: matchedSupplier?.id || updatedFormData?.supplier_id,
        name: matchedSupplier?.name || updatedFormData?.supplier_id
      };
    }
    if (updatedFormData?.address && !updatedFormData.addressInfo.latitude && !updatedFormData.addressInfo.longitude) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: updatedFormData.address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && results?.length) {
          updatedFormData.addressInfo = {
            ...updatedFormData.addressInfo,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          };
        }
      });
    }

    update[index] = {
      ...update[index],
      formData: updatedFormData,
      dirty: dirty,
      valid: valid
    };

    outsideInventoryFormSubject.next(update);

    checkFormsValid();
  }
};

export const submitCreateForm = async (): Promise<CreateOutsideInventoryState[]> => {
  const forms = outsideInventoryFormSubject.getValue();
  const creationState: CreateOutsideInventoryState[] = [];
  const promises = forms.map(async (form) => {
    const result = await createOutsideInventory(form.formData);
    const reqState: CreateOutsideInventoryState = {
      id: form.id,
      status: result.data ? FormStateStatus.SUCCESS : FormStateStatus.SUBMITTING,
      error: result.error?.message,
      data: result.data || undefined,
      requestBody: form.formData
    };
    creationState.push(reqState);
  });
  await Promise.all(promises);
  outsideInventoryCreateStateSubject.next(creationState);
  return creationState;
};

export const resubmitIndividualitem = async (id: string, body: InventoryAsset) => {
  const result = await createOutsideInventory(body);
  const update = [...outsideInventoryCreateStateSubject.getValue()];
  const index = update.findIndex((state) => state.id === id);

  if (index > -1) {
    update[index] = {
      id: id,
      status: result.data ? FormStateStatus.SUCCESS : FormStateStatus.FAILED,
      error: result.error?.message,
      data: result.data || undefined,
      requestBody: body
    };
  }

  outsideInventoryCreateStateSubject.next(update);
};

export const initUpdateForm = (formData: InventoryAsset) => {
  const updateForm: OutsideInventoryFormProps = {
    id: v4(),
    dirty: false,
    valid: true,
    formData: { ...formData, showAdvanced: true }
  };
  outsideInventoryFormSubject.next([updateForm]);
  checkFormsValid();
};

export const submitUpdateForm = async (): Promise<CreateOutsideInventoryState[]> => {
  const forms = outsideInventoryFormSubject.getValue();
  const creationState: CreateOutsideInventoryState[] = [];
  const promises = forms.map(async (form) => {
    const result = await updateOutsideInventory(form.formData.id, form.formData);
    const reqState: CreateOutsideInventoryState = {
      id: form.id,
      status: result.data ? FormStateStatus.SUCCESS : FormStateStatus.FAILED,
      error: result.error?.message,
      data: result.data || undefined,
      requestBody: form.formData
    };
    creationState.push(reqState);
  });
  await Promise.all(promises);
  outsideInventoryCreateStateSubject.next(creationState);
  return creationState;
};

export const updateFormProduct = (
  formId: string,
  product: Product,
  allSchemas: OutsideInventorySchema[],
  accountId?: string
): OutsideInventorySchema | undefined => {
  const forms = outsideInventoryFormSubject.getValue();
  const form = forms.find((state) => state.id === formId);

  if (!form) return;
  const { formData } = form;

  const matchedSchema = allSchemas
    .filter((schema) => schema.product_id === product.id)
    .reduce(
      (latestVersion, schema): OutsideInventorySchema => {
        if (schema.version > latestVersion.version) return schema;
        return latestVersion;
      },
      { version: 0 } as OutsideInventorySchema
    );
  if (matchedSchema) {
    const update = { ...formData };
    update.product_id = product.id;
    update.product = { ...product };
    update.outside_inventory_schema_id = matchedSchema.id;
    update.account_id = accountId;
    updateForm(formId, update, false);
    return matchedSchema;
  }
};

const checkFormsValid = () => {
  const forms = [...outsideInventoryFormSubject.getValue()];
  const allFormsValid = forms.every((form) => form.valid);
  outsideInventoryFormValidSubject.next(allFormsValid);
};

export const downloadTemplate = async () => {
  const token = localStorage.getItem('token');
  if (!token) emitErrorState('Not authenticated or missing token');

  axios({
    url: `${process.env.REACT_APP_API_SERVER_URL}/api/oi/template`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then((response: { data: Blob | MediaSource }) => {
      const href = URL.createObjectURL(response.data);
      const sanitizedHref = DOMPurify.sanitize(href);
      const link = document.createElement('a');
      link.href = sanitizedHref;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((err) => {
      emitErrorState(err.message);
    });
};

export const uploadFileValidation = async (file: File, progressHandler: ProgressHandler, accountId: string) => {
  const { data, error } = await uploadXLFileValidation(file, progressHandler, accountId);
  if (error) emitErrorState(error.message);
  if (data) {
    data.errors = data.errors.map((er) => ({
      ...er,
      id: v4()
    }));
    outsideInventoryValidationStateSubject.next(data);
  }
};
